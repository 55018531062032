import { render, staticRenderFns } from "./CalendarGS2.vue?vue&type=template&id=68dc8c70&scoped=true&"
import script from "./CalendarGS2.vue?vue&type=script&lang=js&"
export * from "./CalendarGS2.vue?vue&type=script&lang=js&"
import style0 from "./CalendarGS2.vue?vue&type=style&index=0&id=68dc8c70&prod&scoped=true&lang=css&"
import style1 from "./CalendarGS2.vue?vue&type=style&index=1&id=68dc8c70&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68dc8c70",
  null
  
)

export default component.exports